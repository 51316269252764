@import url("https://use.typekit.net/mpw5dkx.css");
@import url('https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,300;0,400;1,600;1,900&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider {
  display: flex !important;
  align-items: center;
  height: 100%;
}

.slick-list {
  flex: auto;
}

/* Works on Firefox and chrome*/
* {
  scrollbar-width: thin;
  scrollbar-color: #CCCCCC #c7c7c7;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  height: 5px; /* height of horizontal scrollbar ← You're missing this */
  width: 5px; /* width of vertical scrollbar */
}

*::-webkit-scrollbar-track {
  background: #c7c7c7;
}

*::-webkit-scrollbar-thumb {
  background-color: #CCCCCC;
  border-radius: 5px;
  border: 3px solid #c7c7c7;
}
